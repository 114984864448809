import checkIcon from "../assets/home/check_icon.svg";
import ReactCountryFlag from "react-country-flag";
import { format } from "date-fns";
import { Cookies } from "react-cookie";
import {
  AsYouType,
  parsePhoneNumberFromString,
  getCountryCallingCode,
} from "libphonenumber-js";
import Moment from "moment-timezone";
import { new_relic_license_key } from "../environment";
import axios from "axios";
import UAParser from "ua-parser-js";

export const validEmail =
  // eslint-disable-next-line
  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9]+(-[a-zA-Z0-9]+)*(\.[a-zA-Z0-9]+(-[a-zA-Z0-9]+)*)*\.[a-zA-Z]{2,}$/;

export const currency_data = {
  AED: "aed",
  ALL: "all",
  AMD: "amd",
  AOA: "aoa",
  ARS: "ars",
  AUD: "aud2",
  AZN: "azn",
  BAM: "bam",
  BBD: "bbd",
  BDT: "bdt",
  BGN: "bgn",
  BND: "bnd",
  BOB: "bob",
  BRL: "brl",
  BSD: "bsd",
  BWP: "bwp",
  BYN: "byn",
  CAD: "cad2",
  CHF: "chf",
  CLP: "clp",
  CNY: "cny",
  COP: "cop",
  CRC: "crc",
  CVE: "cve",
  CZK: "czk",
  DKK: "dkk",
  DOP: "dop",
  DZD: "dzd",
  EGP: "egp",
  EUR: "eur2",
  FJD: "fjd",
  GBP: "gbp",
  GEL: "gel",
  GTQ: "gtq",
  GYD: "gyd",
  HKD: "hkd",
  HUF: "huf",
  IDR: "idr",
  ILS: "ils",
  INR: "inr",
  ISK: "isk",
  JPY: "jpy",
  KRW: "krw",
  KZT: "kzt",
  LKR: "lkr",
  MAD: "mad",
  MDL: "mdl",
  MXN: "mxn2",
  MYR: "myr",
  NAD: "nad",
  NGN: "ngn",
  NOK: "nok",
  NZD: "nzd",
  PAB: "pab",
  PEN: "pen",
  PHP: "php",
  PKR: "pkr",
  PLN: "pln",
  QAR: "qar",
  RON: "ron",
  RSD: "rsd",
  SAR: "sar",
  SCR: "scr",
  SEK: "sek",
  SGD: "sgd",
  SZL: "szl",
  THB: "thb",
  TRY: "try",
  TTD: "ttd",
  TWD: "twd",
  UAH: "uah",
  USD: "usd2",
  UYU: "uyu",
  VND: "vnd",
  XCD: "xcd",
  ZAR: "zar"
};
export const getFinalValue = (index, t, data) => {
  switch (index) {
    case 0:
      return (
        <div
          className={`animated__table-value ${
            data.timezone === "N/A" ? `animated_blurry_title` : ""
          }`}
        >
          {data.timezone === "N/A" ? t("TIMEZONE") : data.timezone}
        </div>
      );
    case 1:
      return t("WIRELESS");
    case 2:
      return (
        <div
          className={`animated__table-value ${
            data.country === "N/A" ? `animated_blurry_title` : ""
          }`}
        >
          {data.country === "N/A" ? (
            t("COUNTRY")
          ) : (
            <ReactCountryFlag
              countryCode={data.country}
              svg
              title={data.country}
              style={{
                width: "2em",
                height: "2em"
              }}
            />
          )}
        </div>
      );
    case 3:
      return (
        <div className={`animated__table-value animated_blurry_title`}>
          {data.city === "N/A" ? t("CITY") : data.city}
        </div>
      );
    case 4:
      return data?.name;
    case 5:
      return (
        <div className="animated__table-value animated_defined">
          <img src={checkIcon} alt="" />
          {t("DEFINED")}
        </div>
      );
    default:
      return "";
  }
};

export const formatDate = (date) => {
  return format(date, "MMMM dd, yyyy");
};
export const renderRTLClass = () => {
  const cookie = new Cookies();
  const lang = cookie.get("lang");
  if (lang === "ac" || lang === "heb" || lang === "ar" || lang === "he") {
    return "theme_dir__rtl";
  } else return "";
};

export const languages = [
  { code: "cs", name: "Cesky", alt: "Cesky", flagCode: "cz" },
  { code: "de", name: "Deutsch", alt: "Deutsch", flagCode: "de" },
  { code: "en", name: "English", alt: "ENGLISH", flagCode: "gb" },
  { code: "es", name: "Español", alt: "Español", flagCode: "es" },
  { code: "el", name: "Ελληνικά", alt: "Ελληνικά", flagCode: "gr" },
  { code: "fr", name: "Français", alt: "FRENCH", flagCode: "fr" },
  { code: "hu", name: "Magyar", alt: "Magyar", flagCode: "hu" },
  { code: "fi", name: "Suomalainen", alt: "Suomalainen", flagCode: "fi" },
  { code: "is", name: "Islenskur", alt: "islenskur", flagCode: "is" },
  { code: "et", name: "Eesti keel", alt: "Eesti keel", flagCode: "ee" },
  { code: "hi", name: "हिंदी", alt: "Hindi", flagCode: "in" },
  { code: "yue", name: "粵語", alt: "Cantonese", flagCode: "cn" },
  { code: "th", name: "แบบไทย", alt: "Thai", flagCode: "th" },
  { code: "bn", name: "বাংলা", alt: "Bengali", flagCode: "bd" },
  { code: "ms", name: "Melayu", alt: "Malay", flagCode: "my" },
  { code: "ko", name: "한국인", alt: "한국인", flagCode: "kr" },
  { code: "hr", name: "Hrvatski", alt: "Hrvatski", flagCode: "hr" },
  { code: "zh-CN", name: "中国人", alt: "Chinese", flagCode: "cn" },
  { code: "ga", name: "Gaeilge", alt: "Gaeilge", flagCode: "ie" },
  { code: "id", name: "Bahasa Indonesia", alt: "Indonesian", flagCode: "id" },
  { code: "ja", name: "日本語", alt: "Japanese", flagCode: "jp" },
  { code: "sv", name: "Svenska", alt: "Svenska", flagCode: "se" },
  { code: "it", name: "Italiano", alt: "Italiano", flagCode: "it" },
  { code: "bg", name: "Български", alt: "Български", flagCode: "bg" },
  { code: "sr", name: "Српски", alt: "Serbian", flagCode: "rs" },
  { code: "uk", name: "Yкраїнська", alt: "Ukrainian", flagCode: "ua" },
  { code: "he", name: "עִברִית", alt: "Hebrew", flagCode: "il" },
  { code: "sk", name: "Slovenský", alt: "Slovak", flagCode: "sk" },
  { code: "da", name: "Dansk", alt: "Danish", flagCode: "dk" },
  { code: "ar", name: "عربي", alt: "Arabic", flagCode: "sa" },
  { code: "nl", name: "Nederlands", alt: "Nederlands", flagCode: "nl" },
  { code: "no", name: "Norsk", alt: "Norsk", flagCode: "no" },
  { code: "pl", name: "Polski", alt: "Polski", flagCode: "pl" },
  { code: "zh", name: "普通话", alt: "Mandarin", flagCode: "cn" },
  { code: "pt", name: "Português", alt: "Português", flagCode: "pt" },
  { code: "ro", name: "Română", alt: "Romanian", flagCode: "ro" },
  { code: "sl", name: "Slovenščina", alt: "Slovenian", flagCode: "si" },
  { code: "tr", name: "Türk", alt: "turkish", flagCode: "tr" },
  {
    code: "pt-br",
    name: "Português(BR)",
    alt: "Portuguese (BR)",
    flagCode: "br",
  },
  { code: "vi", name: "Tiếng Việt", alt: "Vietnamese", flagCode: "vn" },
  { code: "bs", name: "Bosanski", alt: "Bosnian", flagCode: "ba" },
  { code: "tk", name: "Türkmençe", alt: "Turkmen", flagCode: "tm" },
  { code: "zu", name: "isiZulu", alt: "Zulu", flagCode: "za" },
  { code: "ru", name: "Русский", alt: "Russian", flagCode: "ru" },
  { code: "lv", name: "Latviešu", alt: "Latvian", flagCode: "lv" },
  { code: "lt", name: "Lietuvių", alt: "Lithuanian", flagCode: "lt" },
  { code: "fil", name: "Filipino", alt: "Filipino", flagCode: "ph" }
];
export const getLang = (lang) => {
  switch (lang) {
    case "cz":
      return "cs";
    case "gr":
      return "el";
    case "kr":
      return "ko";

    default:
      return lang;
  }
};

export const calculateCursorPosition = (
  input,
  formattedPhoneNumber,
  cursorPosition,
  isDeleting
) => {
  let formattedIndex = 0;
  let inputIndex = 0;
  while (
    inputIndex < cursorPosition &&
    formattedIndex < formattedPhoneNumber.length
  ) {
    if (input[inputIndex] === formattedPhoneNumber[formattedIndex]) {
      inputIndex++;
    }
    formattedIndex++;
  }
  while (
    formattedIndex < formattedPhoneNumber.length &&
    formattedPhoneNumber[formattedIndex] === " "
  ) {
    if (formattedPhoneNumber[formattedIndex] === " " && isDeleting) {
      return formattedIndex;
    }
    formattedIndex++;
  }
  return formattedIndex;
};

export const handleSetPhoneNumber = (
  event,
  setPhoneNumber,
  setNumberValidate,
  setCountryCode,
  countryCodeinput,
  setDialCode,
  setFinalFormattedNumber,
  inputRef,
  mobileInputRef
) => {
  setNumberValidate("");
  let { value } = event.target;
  const input = event.target.value;
  const cursorPosition = event.target.selectionStart;
  const formatter = new AsYouType(countryCodeinput.toUpperCase());
  let formattedPhoneNumber;
  let parsedNumber;
  let formattedUKNumber;

  if (value.length > 5) {
    parsedNumber = parsePhoneNumberFromString(value, {
      defaultCountry: countryCodeinput.toUpperCase(),
    });
    if (countryCodeinput === "gb" || countryCodeinput === "GB") {
      formattedUKNumber = parsedNumber.formatNational();
      if (formattedUKNumber.startsWith("0")) {
        formattedUKNumber = formattedUKNumber.replace("0", "");
      }
    }
  }

  if (parsedNumber && parsedNumber.isValid()) {
    formattedPhoneNumber = parsedNumber.formatNational();
    if (formattedPhoneNumber.startsWith("0")) {
      formattedPhoneNumber = formattedPhoneNumber.substring(1).trim();
    }
  } else {
    formattedPhoneNumber = formatter.input(value);
  }

  setPhoneNumber(formattedPhoneNumber);
  requestAnimationFrame(() => {
    const newCursorPosition = calculateCursorPosition(
      input,
      formattedPhoneNumber,
      cursorPosition,
      event.nativeEvent.inputType === "deleteContentBackward"
    );
    if (inputRef?.current) {
      inputRef?.current?.setSelectionRange(newCursorPosition, newCursorPosition);
    }
    if (mobileInputRef?.current) {
      mobileInputRef?.current.setSelectionRange(newCursorPosition, newCursorPosition);
    }
  });

  if (parsedNumber && parsedNumber.country) {
    setCountryCode(parsedNumber.country.toLowerCase());
    setDialCode(parsedNumber.countryCallingCode);
    setFinalFormattedNumber(formattedPhoneNumber);
  }
};


export const handleSetNumber = (
  value,
  country,
  setDialCode,
  setNumberValidate,
  setCountryCode,
  setPhoneNumber,
  setFinalFormattedNumber,
  phoneNumber,
  countryCodeinput
) => {
  setDialCode(country.dialCode);
  setNumberValidate("");
  setCountryCode(country.countryCode);
  // value = formatPhoneNumber(phoneNumber, country.countryCode.toUpperCase());
  value = formatPhoneNumber(
    String(phoneNumber),
    country?.countryCode?.toUpperCase() || countryCodeinput?.toUpperCase(),
    setDialCode,
    setFinalFormattedNumber
  );
  setPhoneNumber(value);
};
const formatPhoneNumber = (
  value,
  countryCode,
  setDialCode,
  setFinalFormattedNumber
) => {
  // Use AsYouType to format the number according to the country code
  value = String(value);
  const formatter = new AsYouType(countryCode);
  let adjustedValue = value;

  const formattedNumber = formatter.input(adjustedValue);
  // Get the country calling code
  const countryCallingCode = getCountryCallingCode(countryCode);

  setDialCode(countryCallingCode);
  const countryCallingCodeWithPlus = `+${countryCallingCode}`;

  // Ensure the formatted number does not start with the country calling code
  let finalFormattedNumber = formattedNumber;
  if (formattedNumber.startsWith(countryCallingCodeWithPlus)) {
    finalFormattedNumber = formattedNumber
      .replace(countryCallingCodeWithPlus, "")
      .trim();
  }
  finalFormattedNumber = finalFormattedNumber.replace(/[()]/g, "").trim();

  // Check if the number starts with brackets and the second character is zero
  if (
    finalFormattedNumber.startsWith("(") ||
    finalFormattedNumber.includes("-")
  ) {
    finalFormattedNumber = finalFormattedNumber.replace(/-/g, "").trim();
  }

  setFinalFormattedNumber(finalFormattedNumber);
  return finalFormattedNumber;
};

const getDeviceInfo = async () => {
  try {
    const parser = new UAParser();
    const result = parser.getResult();

    let deviceInfo = {
      "OS Name": result.os?.name || "N/A",
      "OS Version": result.os?.version || "N/A",
      "Browser Name": result.browser?.name || "N/A",
      "Browser Version": result.browser?.version || "N/A",
      "Device Type": result.device?.type || "N/A",
      "Device Name": "N/A",
      "Device Version": "N/A",
      "User Agent": result.ua || "N/A"
    };
    const highEntropyValues = navigator.userAgentData?.getHighEntropyValues([
      "model",
      "platformVersion"
    ]);
    if (highEntropyValues) {
      const {model, platformVersion} = await highEntropyValues.catch(
        () => ({})
      );
      deviceInfo["Device Name"] = model || "N/A";
      deviceInfo["Device Version"] = platformVersion || "N/A";
    }
    return deviceInfo;
  } catch (error) {
    console.log("error from getUserInfo", error);
    return null;
  }
};

export const logAPI = async (request, message, body, responseStatus = 200) => {
  if (!new_relic_license_key) return true;
  try {
    const nowInEST = Moment().tz("America/New_York");
    const unixTimestampEST = nowInEST.utc().unix();
    const payload = {
      timestamp: unixTimestampEST,
      message: message,
      logtype: "accesslogs",
      service: "subscription-service",
      hostname: "ubuntu-s-4vcpu-8gb-240gb-intel-sfo3-01",
      site: "location-tool",
      responseStatus: responseStatus,
      environment: "production",
      request: request,
      verb: "POST"
    };
    if (body) payload.body = body;
    const deviceInfo = await getDeviceInfo();
    if (deviceInfo) {
      payload.deviceInfo = deviceInfo;
    }
    if (navigator.userAgent) payload.userAgent = navigator.userAgent;
    let response = await axios.post(
      "https://log-api.newrelic.com/log/v1",
      payload,
      {
        headers: {
          "Api-Key": new_relic_license_key,
          "Content-Type": "application/json"
        }
      }
    );
    console.log(response.data);
  } catch (error) {
    console.log("new relic log api error", error);
  }
  return true;
};

export const formatNumberByCountry = (number) => {
  try {
    // Parse the phone number to infer the country
    const phoneNumber = parsePhoneNumberFromString(number);

    if (phoneNumber && phoneNumber.isValid()) {
      // Extract the national format
      let nationalFormat = phoneNumber.formatNational();

      // Remove leading '0' if unnecessary in the international format
      if (nationalFormat.startsWith('0')) {
        nationalFormat = nationalFormat.substring(1).trim();
      }

      // Combine with the international calling code
      return `+${phoneNumber.countryCallingCode} ${nationalFormat}`;
    } else {
      // If the number is invalid, return it as is
      return number;
    }
  } catch (error) {
    console.error("Error formatting phone number:", error);
    return number; // Return the original number if an error occurs
  }
};
export function trimValue(value) {
  return value.trim().replaceAll(" ", "");
}
